<route>
{
  "meta": {
    "permission": [
      "Invoices.view_invoices"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="start"
          :label="$t('start_date')"
          color="primary"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="end"
          :label="$t('end_date')"
          color="primary"
          :min="start"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="invoice_number"
          :name="$t('number')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.invoice_number"
            outlined
            :error-messages="errors[0]"
            :label="$t('number')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="status"
          :name="$t('status')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.status"
            outlined
            :error-messages="errors[0]"
            :label="$t('status')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="$t('invoicesReport')"
      :headers="headers"
      app="Invoices.invoices"
      :opt="{
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        invoice_number: this.form.invoice_number,
        status: this.form.status
      }"
      api="invoice"
      dontCreate
      dontRemove
      dontEdit
      dontSearch
    >
      <template v-slot:item.date="{ item }">
        <span v-text="item.date != null ? dateFormat(item.date) : ''"></span>
      </template>
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('invoice', 2) })"
          color="text-dark"
          @click="getInvoicesExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      modalBD: false,
      modalBD2: false,
      start: '',
      end: '',
      form: {
        invoice_number: '',
        date_from: '',
        date_to: '',
        status: ''
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('number'),
          value: 'invoice_number'
        },
        {
          text: this.$t('status'),
          value: 'status'
        },
        {
          text: this.$t('date'),
          value: 'date'
        },
        {
          text: this.$t('tax'),
          value: 'tax'
        },
        {
          text: this.$tc('subtotal', 1),
          value: 'subtotal'
        },
        {
          text: this.$t('balance'),
          value: 'balance'
        },
        {
          text: this.$t('total'),
          value: 'total'
        }
      ]
    }
  },
  methods: {
    getInvoicesExcel() {
      this.downloadFile({
        api: 'invoice',
        method: 'excel',
        filename: 'Invoices',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?status=' +
            this.form.status +
            '&invoice_number=' +
            this.form.invoice_number +
            '&date_from=' +
            this.form.date_from +
            '&date_to=' +
            this.form.date_to
        }
      })
    }
  },
  watch: {
    start: {
      handler(val) {
        if (val != null && val != '' && val != undefined) {
          this.form.date_from = this.dateFormat(val)
        }
      }
    },
    end: {
      handler(val) {
        if (val != null && val != '') {
          this.form.date_to = this.dateFormat(val)
        }
      }
    }
  }
}
</script>
